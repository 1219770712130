<template>
  <b-card
    class="under-line-title change-pass w-100 "
    :title="$t('profile.ChangePassTitle')"
  >
    <validation-observer ref="loginForm" #default="{ invalid }">
      <b-form @submit.prevent="updatePassword" @reset.prevent="discardPassword">
        <b-row class="change-password-scroll">
          <!-- password --->
          <b-col cols="6" class="change-pass">
            <b-form-group :label="$t('profile.OldPass')">
              <validation-provider
                #default="{ errors }"
                name="old password"
                vid="old_password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="{
                    'is-invalid': errors.length > 0,
                    'is-valid': errors.length === 0 && user.old_password
                  }"
                >
                  <b-input-group-prepend
                    is-text
                    :class="{
                      'is-invalid': errors.length > 0,
                      'is-valid': errors.length === 0 && user.old_password
                    }"
                  >
                    <feather-icon icon="LockIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="old_password"
                    :type="oldPasswordFieldType"
                    v-model="user.old_password"
                    :state="errors.length > 0 ? false : null"
                    :class="{
                      'is-invalid': errors.length > 0,
                      'is-valid': errors.length === 0 && user.old_password
                    }"
                    class="form-control-merge"
                    name="old_password"
                    :placeholder="$t('profile.PassPlaceholder')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="oldPasswordToggleIcon"
                      @click="togglePasswordVisibility('old_pass')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors[0] ? handleError(errors[0]) : ""
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group :label="$t('profile.NewPass')">
              <validation-provider
                #default="{ errors }"
                name="new password"
                vid="newPassword"
                :rules="{
                  required: true,
                  regex: /^.{5,25}$/
                }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="{
                    'is-invalid': errors.length > 0,
                    'is-valid': errors.length === 0 && user.new_password
                  }"
                >
                  <b-input-group-prepend
                    is-text
                    :class="{
                      'is-invalid': errors.length > 0,
                      'is-valid': errors.length === 0 && user.new_password
                    }"
                  >
                    <feather-icon icon="LockIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    :class="{
                      'is-invalid': errors.length > 0,
                      'is-valid': errors.length === 0 && user.new_password
                    }"
                    id="newPassword"
                    :type="newPasswordFieldType"
                    v-model="user.new_password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="newPassword"
                    :placeholder="$t('profile.PassPlaceholder')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="newPasswordToggleIcon"
                      @click="togglePasswordVisibility('new_pass')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors[0] ? handleError(errors[0]) : ""
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group :label="$t('profile.RePass')">
              <validation-provider
                #default="{ errors }"
                name="retype new password"
                vid="retype new password"
                :rules="{
                  required: true,
                  regex: /^.{5,25}$/
                }"
              >
                <span style="display: none">
                  {{
                    !errors || !errors.length
                      ? user.confirm_password != user.new_password
                        ? (errors = [$t("profile.PasswordMismatch")])
                        : ""
                      : ""
                  }}</span
                >
                <b-input-group
                  class="input-group-merge"
                  :class="{
                    'is-invalid': errors.length > 0,
                    'is-valid': errors.length === 0 && user.confirm_password
                  }"
                >
                  <b-input-group-prepend
                    is-text
                    :class="{
                      'is-invalid': errors.length > 0,
                      'is-valid': errors.length === 0 && user.confirm_password
                    }"
                  >
                    <feather-icon icon="LockIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    :class="{
                      'is-invalid': errors.length > 0,
                      'is-valid': errors.length === 0 && user.confirm_password
                    }"
                    id="RetypeNewPassword"
                    :type="confirmPasswordFieldType"
                    v-model="user.confirm_password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="RetypeNewPassword"
                    :placeholder="$t('profile.PassPlaceholder')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="confirmPasswordToggleIcon"
                      @click="togglePasswordVisibility('confirm_pass')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors[0] ? handleError(errors[0]) : ""
                }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                class="form-control-merge"
                v-model="user.is_logout_all"
              >
                {{ $t("profile.logoutAllAccounts") }}
              </b-form-checkbox>
            </b-form-group>
            <div>
              <b-button
                type="submit"
                variant="primary"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="invalid"
                class="mt-2 mr-1"
              >
                {{ $t("profile.SaveChanges") }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="reset"
                class="mt-2"
              >
                {{ $t("profile.Discard") }}
              </b-button>
            </div>

            <div class="hasErrcss">
              <small class="text-danger">{{ hasErr }}</small>
            </div>
          </b-col>
          <b-col cols="6" class="hidden-mobile">
            <div class="chnage-pass-img">
              <ChangePass />
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <Loader :show="show" />
  </b-card>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Loader from "@/layouts/components/Loader.vue";
import ChangePass from "@/assets/images/pages/change-pass.svg";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BImg,
  BCard
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";

import AuthService from "@/libs/api/auth-service";
export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BForm,
    BButton,
    BImg,
    ValidationProvider,
    ValidationObserver,
    Loader,
    BCard,
    ChangePass
  },
  mixins: [togglePasswordVisibility],
  computed: {
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === "password"
        ? "EyeOffIcon"
        : "EyeIcon";
    },
    oldPasswordToggleIcon() {
      return this.oldPasswordFieldType === "password"
        ? "EyeOffIcon"
        : "EyeIcon";
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === "password"
        ? "EyeOffIcon"
        : "EyeIcon";
    }
  },
  directives: {
    Ripple
  },
  data() {
    return {
      hasErr: "",
      show: false,
      user: {
        old_password: "",
        new_password: "",
        confirm_password: "",
        is_logout_all: false
      }
    };
  },
  methods: {
    async updatePassword() {
      let hasErr = "";
      this.user.old_password =
        this.user.old_password && this.user.old_password.trim();
      this.user.new_password =
        this.user.new_password && this.user.new_password.trim();
      this.user.confirm_password =
        this.user.confirm_password && this.user.confirm_password.trim();

      if (!this.user.old_password) {
        hasErr = this.$t("profile.RequiredOldPassword");
      } else if (!this.user.new_password) {
        hasErr = this.$t("profile.RequiredNewPassword");
      } else if (!this.user.confirm_password) {
        hasErr = this.$t("profile.RequiredConfirmPassword");
      } else if (this.user.new_password !== this.user.confirm_password) {
        hasErr = this.$t("profile.PasswordMismatch");
      } else if (this.user.new_password === this.user.old_password) {
        hasErr = this.$t("profile.PasswordShouldNotSame");
      } else if (!this.isValidPassword(this.user.new_password)) {
        hasErr = this.$t("errors.Password_Valid");
      } else if (!this.isValidPassword(this.user.confirm_password)) {
        hasErr = this.$t("errors.Password_Valid");
      }
      const userInfo =
        localStorage.getItem("USER_PROFILE_DETAILS") &&
        JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS"));
      if (
        userInfo &&
        userInfo.username.toLowerCase() === this.user.new_password.toLowerCase()
      ) {
        hasErr = this.$t("errors.UserNamePasswordShouldNotSame");
      }

      if (hasErr && hasErr !== "") {
        this.hasErr = hasErr;

        return;
      }
      try {
        this.show = true;
        let response = await new AuthService().changePassword({
          old_password: this.user.old_password,
          new_password: this.user.new_password,
          re_type_password: this.user.confirm_password,
          is_logout_all: this.user.is_logout_all || false
        });
        if (response && response.data && response.data.success) {
          this.show = false;
          this.hasErr = "";
          localStorage.setItem(
            useJwt.jwtConfig.storageTokenKeyName,
            JSON.stringify(response.data.user_token)
          );
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("profile.PasswordUpdatedSuccess"),
              icon: "exclamation-circle-fill",
              variant: "success"
            }
          });
          await new AuthService().logout({
            session_id: localStorage.getItem("sessionId")
          });
          // Redirect to login page
          this.$router.push({ name: "login" });
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.hasErr = response.error.message;
        }
      } catch (err) {
        this.show = false;
        // this.$router.push("/").catch(() => {});
        this.hasErr = err.message;
      }
    },
    async discardPassword() {
      this.$refs.loginForm.reset();
      this.user = {
        old_password: "",
        new_password: "",
        confirm_password: ""
      };
      this.hasErr = "";
      return;
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    }
  }
};
</script>
<style lang="scss">
@import "./index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.primary-fill {
  fill: var(--primary);
}
svg.verify-svg,
.verify-svg path {
  fill: var(--primary);
}
svg.verify-svg .path-1 {
  fill: var(--primary);
}
.white-fill {
  fill: var(--white);
}
.hasErrcss {
  margin-top: 10px;
}
.change-password-scroll{
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden
}
</style>
