var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"under-line-title change-pass w-100 ",attrs:{"title":_vm.$t('profile.ChangePassTitle')}},[_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword($event)},"reset":function($event){$event.preventDefault();return _vm.discardPassword($event)}}},[_c('b-row',{staticClass:"change-password-scroll"},[_c('b-col',{staticClass:"change-pass",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('profile.OldPass')}},[_c('validation-provider',{attrs:{"name":"old password","vid":"old_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{
                  'is-invalid': errors.length > 0,
                  'is-valid': errors.length === 0 && _vm.user.old_password
                }},[_c('b-input-group-prepend',{class:{
                    'is-invalid': errors.length > 0,
                    'is-valid': errors.length === 0 && _vm.user.old_password
                  },attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{staticClass:"form-control-merge",class:{
                    'is-invalid': errors.length > 0,
                    'is-valid': errors.length === 0 && _vm.user.old_password
                  },attrs:{"id":"old_password","type":_vm.oldPasswordFieldType,"state":errors.length > 0 ? false : null,"name":"old_password","placeholder":_vm.$t('profile.PassPlaceholder')},model:{value:(_vm.user.old_password),callback:function ($$v) {_vm.$set(_vm.user, "old_password", $$v)},expression:"user.old_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.oldPasswordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('old_pass')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('profile.NewPass')}},[_c('validation-provider',{attrs:{"name":"new password","vid":"newPassword","rules":{
                required: true,
                regex: /^.{5,25}$/
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{
                  'is-invalid': errors.length > 0,
                  'is-valid': errors.length === 0 && _vm.user.new_password
                }},[_c('b-input-group-prepend',{class:{
                    'is-invalid': errors.length > 0,
                    'is-valid': errors.length === 0 && _vm.user.new_password
                  },attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{staticClass:"form-control-merge",class:{
                    'is-invalid': errors.length > 0,
                    'is-valid': errors.length === 0 && _vm.user.new_password
                  },attrs:{"id":"newPassword","type":_vm.newPasswordFieldType,"state":errors.length > 0 ? false : null,"name":"newPassword","placeholder":_vm.$t('profile.PassPlaceholder')},model:{value:(_vm.user.new_password),callback:function ($$v) {_vm.$set(_vm.user, "new_password", $$v)},expression:"user.new_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.newPasswordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('new_pass')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('profile.RePass')}},[_c('validation-provider',{attrs:{"name":"retype new password","vid":"retype new password","rules":{
                required: true,
                regex: /^.{5,25}$/
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('span',{staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s(!errors || !errors.length ? _vm.user.confirm_password != _vm.user.new_password ? (errors = [_vm.$t("profile.PasswordMismatch")]) : "" : ""))]),_c('b-input-group',{staticClass:"input-group-merge",class:{
                  'is-invalid': errors.length > 0,
                  'is-valid': errors.length === 0 && _vm.user.confirm_password
                }},[_c('b-input-group-prepend',{class:{
                    'is-invalid': errors.length > 0,
                    'is-valid': errors.length === 0 && _vm.user.confirm_password
                  },attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{staticClass:"form-control-merge",class:{
                    'is-invalid': errors.length > 0,
                    'is-valid': errors.length === 0 && _vm.user.confirm_password
                  },attrs:{"id":"RetypeNewPassword","type":_vm.confirmPasswordFieldType,"state":errors.length > 0 ? false : null,"name":"RetypeNewPassword","placeholder":_vm.$t('profile.PassPlaceholder')},model:{value:(_vm.user.confirm_password),callback:function ($$v) {_vm.$set(_vm.user, "confirm_password", $$v)},expression:"user.confirm_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.confirmPasswordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('confirm_pass')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{staticClass:"form-control-merge",model:{value:(_vm.user.is_logout_all),callback:function ($$v) {_vm.$set(_vm.user, "is_logout_all", $$v)},expression:"user.is_logout_all"}},[_vm._v(" "+_vm._s(_vm.$t("profile.logoutAllAccounts"))+" ")])],1),_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("profile.SaveChanges"))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"}},[_vm._v(" "+_vm._s(_vm.$t("profile.Discard"))+" ")])],1),_c('div',{staticClass:"hasErrcss"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.hasErr))])])],1),_c('b-col',{staticClass:"hidden-mobile",attrs:{"cols":"6"}},[_c('div',{staticClass:"chnage-pass-img"},[_c('ChangePass')],1)])],1)],1)]}}])}),_c('Loader',{attrs:{"show":_vm.show}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }